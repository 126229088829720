/* eslint-disable react/no-unstable-nested-components */
import React from "react";
import { createGlobalStyle } from "styled-components";
import Home from "./views/Home";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background: #1F274E;
  }
`;

function App() {
  return [<GlobalStyle />, <Home />];
}

export default App;

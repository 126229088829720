import React from "react";
import loadingImage from "src/assets/meuexamesite.jpg";
import Container from "./styles";

export default () => (
  <Container>
    <main>
      <img
        src={loadingImage}
        title="Em breve"
        alt="Logo da Meu Exame, em desenvolvimento"
      />
      <a
        href="https://aguafria.ba.gov.br/site/secretarias"
        target="_blank"
        id="linkSecretariaAguaFria"
        rel="noreferrer"
        aria-label="Secretaria de Saúde de Água Fria - BA"
      />
      <a
        href="https://orango.io"
        target="_blank"
        id="linkOrango"
        rel="noreferrer"
        aria-label="ORANGO I/O TECNOLOGIA"
      />
    </main>
  </Container>
);

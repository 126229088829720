import styled from "styled-components";

export default styled.section`
  width: 100vw;
  height: 100vh;

  main {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-211px, -106px);

    a {
      display: block;
      position: absolute;
      height: 15px;
    }

    #linkOrango {
      bottom: 45px;
      left: 168px;
      width: 179px;
    }

    #linkSecretariaAguaFria {
      bottom: 28px;
      left: 155px;
      width: 201px;
    }
  }
`;
